
import { Vue, Component, Prop } from "vue-property-decorator";
import OldHeader from "./old-header.vue";
import copy from "copy-to-clipboard";

@Component({
  components: {
    OldHeader
  }
})
export default class extends Vue {
  @Prop(String) readonly data!: string;
  @Prop(String) readonly popup_messages!: string;
  @Prop(String) readonly locale!: string;
  @Prop(Object) readonly trans!: Record<string, string>;
  timerCopied: number | undefined;

  hide = false;
  copied = false;
  showOldHeader = true;
  headerPanel = true;

  text = "";
  account_id = "";
  password = "";
  server = "";
  platform = "";

  beforeDestroy() {
    clearTimeout(this.timerCopied);
  }

  beforeMount() {
    const data = JSON.parse(this.data);
    if (Object.keys(data).length) {
      this.showOldHeader = false;
      const { account_id, password, server, platform } = data;
      this.account_id = account_id;
      this.password = password;
      this.server = server;
      if (platform === "mt5") {
        this.platform = "MetaTrader 5";
      } else {
        this.platform = platform;
      }
    } else {
      this.showOldHeader = true;
    }
  }
  hideHeaderPanel() {
    this.headerPanel = false;
  }
  showHeaderPanel() {
    this.headerPanel = true;
  }
  toggleHide() {
    this.hide = !this.hide;
  }
  toggleHeaderPanel() {
    this.headerPanel = !this.headerPanel;
  }
  clipboard() {
    copy(this.password);
    this.copied = true;
    clearTimeout(this.timerCopied);
    this.timerCopied = window.setTimeout(() => (this.copied = false), 3000);
  }
}
