
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop(Object) readonly trans!: Record<string, string>;
  @Prop(Boolean) readonly isActive!: boolean;
  @Prop(Function) readonly toggleHeaderPanel!: any;
  @Prop(String) readonly locale!: string;
  actionsOpen = false;
  showShadow = false;

  pressBurger() {
    if (this.$props.isActive) {
      this.actionsOpen = true;
      this.showShadow = true;
    } else {
      this.$props.toggleHeaderPanel();
    }
  }
  hideMenu() {
    this.actionsOpen = false;
    this.showShadow = false;
  }
}
