import Vue from "vue";
import WebtraderHeader from "./WebtraderHeader";

const widgets: Record<string, any> = {
  "webtrader-header": WebtraderHeader
};

Object.keys(widgets).forEach(name => {
  [...document.getElementsByTagName(name)].forEach(el => {
    new Vue({
      el,
      components: {
        [name]: widgets[name]
      }
    });
  });
});
